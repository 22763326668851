import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useParams } from "react-router-dom";

import './styles.css';

function ProductsByCategory() {
    const { category } = useParams();
    const [products, setProducts] = useState([]);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/category/${category}`);
                setProducts(response.data);
                // console.log(category);
            } catch (error) {
                console.error('Erro ao buscar produtos por categoria:', error);
            }
        };

        fetchProducts();
    }, [category]);

    return (
        <>
            <div className='container-fluid'>
                <div className='row cards'>
                    {products && products.map((product) => (
                        <div className="col-sm-12 col-md-3" key={product._id}>
                            <div className="card">
                                <div className="card-title">
                                    <h3>{product.name}</h3>
                                </div>
                                <div className="card-img">                                   
                                    <img src={`https://apicardapio.gigabytetelecom.com.br:3001/assets/products/${product.foto}`} alt="product" />                                    
                                    <TextRender htmlContent={product.description} />
                                    <h4>R$ {product.price}</h4>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>

    );
}

export default ProductsByCategory;
