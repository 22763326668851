import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../../assets/images/logo3-removebg-preview.png'; // Substitua pelo caminho da sua imagem de logo

import './styles.css';

const NavBar = () => {

  const [categories, setCategories] = useState([]);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://apicardapio.gigabytetelecom.com.br:3001/api/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  const handleLinkClick = () => {
    // Fechar o menu ao clicar no link
    setExpanded(false);
  };


  return (
    <Navbar expand="lg" fixed="top" className="custom-navbar" expanded={expanded}>
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Navbar.Brand href="/" className="custom-img">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <div className="navbar-text">Qual vai ser o pedido de hoje?</div>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            {/* Links à direita */}
            {categories.map((category) => (
              <li key={category._id}>
                <Link
                  to={`/products/category/${category._id}`}
                  className="custom-link"
                  onClick={handleLinkClick}
                >
                  {category.name}
                </Link>
              </li>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
