// CategoryList.js

import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link } from 'react-router-dom';

import './styles.css';

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://apicardapio.gigabytetelecom.com.br:3001/api/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  return (
    // <div>
    //   <h2>Categorias</h2>
    //   <ul>
    //     {categories.map((category) => (
    //       <li key={category._id}>
    //         <h3>{category.name}</h3>
    //         <img src={`http://localhost:3001/assets/categories/${category.foto}`} alt={category.name} />
    //       </li>
    //     ))}
    //   </ul>
    // </div>
    <div className='container-fluid'>
            <div className='row cards'>
                {categories && categories.map((category) => (
                    <div className="col-sm-12 col-md-2" key={category._id}>
                        <div className="card">
                            <Link to={`/products/category/${category._id}`}>
                                <div className="card-title">
                                    <h3>{category.name}</h3>
                                </div>
                                <div className="card-img">
                                    <img src={`https://apicardapio.gigabytetelecom.com.br:3001/assets/categories/${category.foto}`} alt="product" />
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
  );
};

export default Categories;
