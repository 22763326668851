// import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import CardHome from './components/CardHome';
import NavBar from './components/Navbar/index.jsx';
import ProductsByCategory from './components/ProductsByCategory'; // Sua componente para mostrar produtos por categoria
import Categories from "./components/Categories/index.jsx";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Categories />} />
          {/* <Route path="/categories" element={<Categories />} />        */}
          <Route exact path="/products/category/:category" element={<ProductsByCategory />} />
        </Routes>      
      </Router>
    </>
  );
}

export default App;
